@use "_modules/variables" as *;
// @use "_modules/mixins" as *;

#mainContainer {
  height: calc(100vh - (80px + 32px));
}

.text-primary {
  font-family: "Patua One", cursive;
}

.sent {
  color: #dc3545;
  border-color: #dc3545;
}

.received {
  color: #1aca95;
  border-color: #1aca95;
}
